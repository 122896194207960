<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="identity">
		<div class="identity-inner">
			<!-- <div class="identity-inner-head">
				<img @click="backBtnFn" src="@/assets/images/mobile/identity/back.png" alt="">
			</div> -->
			<div class="identity-inner-title">
				请选择你的的身份
			</div>
			<div class="identity-inner-card">
				<div @click="selectIdentity(item)" v-for="(item, index) in identityList" :key="index"
					class="inner-card-item">
					<img :src="item.url" alt="">
					<div class="inner-card-text">
						<span>{{item.cn}}</span>
						<span>{{item.en}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import lib from '@/utils/common.js';
	import {
		getConfigCode
	} from "@/api/mobile/user";
	import storage from 'store'
	export default {
		data() {
			return {
				identityList: [{
						url: require("@/assets/images/mobile/identity/student.png"),
						cn: "学生",
						en: "Student",
						type: "S"
					},
					{
						url: require("@/assets/images/mobile/identity/teacher.png"),
						cn: "学校老师",
						en: "Teacher",
						type: "T"
					}
				]
			}
		},
		methods: {
			selectIdentity(item) {
				// console.log(item.type)
				// storage.set("identityType",item.type)
				// getConfigCode().then(res=>{
				// 	console.log(res)
				// 	if(res.code == 0){
				// 		var pathUrl=''
				// 		if(item.type=='S'){
				// 			pathUrl= "http://www.57qingong.zjut.edu.cn/#/mb/activity/index"
				// 		}else{
				// 			pathUrl= "http://www.57qingong.zjut.edu.cn/#/mb/manhour/index"
				// 		}
				// 		const uri = encodeURIComponent(pathUrl)
				// 		const REDIRECT_URI =res.data.baseUrl+'/cas/oauth2.0/authorize?response_type=code&client_id='+res.data.appKey+'&redirect_uri='+uri
				// 		location.href = REDIRECT_URI
				// 	}
				// })
					this.$router.push({
						path: `/mb/user/login?identityMb=${item.type}`
					})
			},
			backBtnFn() {
				if (this.$dd.env.platform !== "notInDingTalk") {
					this.$dd.ready(() => {
						this.$dd.biz.navigation.close({
							onSuccess: function(result) {},
							onFail: function(err) {}
						})
					})
				}
			}
		},
		destroyed() {
			window.removeEventListener('popstate', this.backBtnFn, false) // 清除监听 否则其他vue路由页面也会被监听
		},
		mounted() {
			window.history.pushState(null, null, document.URL)
			window.addEventListener('popstate', this.backBtnFn, false) // 监听到popstate调用backBtnFn
		},
	}
</script>
<style lang="scss" scoped>
	.identity {
		width: 100%;
		height: calc(100vh - 0.68rem);
		background-image: url("../../../assets/images/mobile/identity/bg.png");
		background-size: 100% calc(100vh - 0.68rem);
		background-repeat: no-repeat;

		.identity-inner {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;

			.identity-inner-head {
				margin: 0.24rem 0.2rem;

				img {
					width: 0.4rem;
					height: 0.4rem;
				}
			}

			.identity-inner-title {
				font-size: 0.4rem;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 0.56rem;
				color: #303133;
				text-align: center;
				margin: 1.6rem 0 1.04rem 0;
			}

			.identity-inner-card {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.inner-card-item {
					width: 5.74rem;
					height: 2.2rem;
					margin-bottom: 0.4rem;
					position: relative;

					img {
						position: absolute;
						top: 0;
						left: 0;
					}

					.inner-card-text {
						position: absolute;
						top: 0.6rem;
						left: 0.48rem;
						display: flex;
						flex-direction: column;
					}

					.inner-card-text :nth-child(1) {
						font-size: 0.32rem;
						font-family: PingFang SC;
						font-weight: bold;
						line-height: 0.44rem;
						color: #FFFFFF;
						margin-bottom: 0.16rem;
					}

					.inner-card-text :nth-child(2) {
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.4rem;
						color: #FFFFFF;
						opacity: 0.6;
					}
				}
			}
		}
	}
</style>
